<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp :display-position="sportsConst.GAME_DISPLAYPOSITION_1x2"></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section" style="position: relative">
            <popup-comp></popup-comp>
            <!--<div style="width: 100%;text-align: center;line-height: 40px;font-weight: bold;color: yellow">
                샘플서버입니다. 절대 <span style="color: #ff4d4d;font-size: 19px">`입금`</span> 하지마세요!!!
            </div>-->
            <div class="main">
                <div class="main_img">
                    <img src="../../assets/images/main/ptn/main01.png" alt="" style="width: 100%">
                </div>
                <div class="sub_img">
                    <div class="sub">
                        <router-link to="/sports" tag="span" style="cursor: pointer">
                            <img src="../../assets/images/main/ptn/main-sports.jpg" alt="">
                        </router-link>
                    </div>
                    <div class="sub">
<!--                        <router-link :to="{path: '/casinohonor', query: {type:1,t: new Date().getTime()}}" tag="span">-->
                        <router-link :to="{path: '/casinohonor', query: {type:1,t: new Date().getTime()}}" tag="span">
                            <img src="../../assets/images/main/ptn/main-casino.png" alt="">
                        </router-link>
                    </div>
                    <div class="sub pc">
<!--                        <router-link to="/casinohonor" tag="span">-->
<!--                        <router-link :to="{path: '/casinohonor', query: {type:2,t: new Date().getTime()}}" tag="span">-->
                        <router-link :to="{path: '/casinohonor', query: {type:2,t: new Date().getTime()}}" tag="span">
                            <img src="../../assets/images/main/ptn/main-slots.png" alt="">
                        </router-link>
                    </div>
                   <!-- <div class="sub">
                        <router-link to="/event" tag="span">
                            <img src="../../assets/images/main/ptn/main-notice.jpg" alt="">
                        </router-link>
                    </div>-->
                    <div class="sub">
                        <router-link :to="{path: '/jiligames', query: {t: new Date().getTime()}}" tag="span">
                            <img src="../../assets/images/main/ptn/main-jili.png" alt="">
                        </router-link>
                    </div>
                  <div class="sub">
                    <router-link to="/sports_inplay" tag="span" style="cursor: pointer">
                      <img src="../../assets/images/main/ptn/main-inplay.png" alt="">
                    </router-link>
                  </div>

<!--                    <div class="sub">-->
<!--                        <router-link to="/event" tag="span">-->
<!--                            <img src="../../assets/images/main/ptn/main-event.png" alt="">-->
<!--                        </router-link>-->
<!--                    </div>-->
                </div>

                <div class="noticeandresult">
                    <div class="nt">
                        <div class="nt_title">
                            Notice <router-link tag="span" to="/notice" style="float: right;font-size: 12px;color: #c1c1c1;cursor: pointer">+more</router-link>
                        </div>
                        <router-link tag="div" :to="{path: '/notice', query: {id:item.id,t: new Date().getTime()}}"
                                     v-for="item in noticeList"
                                     class="nt_cont">
<!--                            <span> <img src="../../assets/images/icon/common/notice.png"></span> {{item.title}}-->
                            <span style="color: #fbce5b"><i class="fa fa-warning"></i></span> {{item.title}}
                        </router-link>
                    </div>
                    <div class="nt">
                        <div class="nt_title">
                            Promo <router-link tag="span" to="/event" style="float: right;font-size: 12px;color: #c1c1c1;cursor: pointer">+more</router-link>
                        </div>
                        <router-link tag="div" :to="{path: '/event', query: {id:item.id,t: new Date().getTime()}}"
                                     v-for="item in eventList"
                                     class="nt_cont">
<!--                            <span> <img src="../../assets/images/icon/common/event.png"></span> {{item.title}}-->
                            <span style="color: #fbce5b"><i class="fa fa-gift"></i></span> {{item.title}}
                        </router-link>
                    </div>
                </div>

<!--                <div class="sub_img">-->
<!--                    <div class="sub">-->
<!--                        <router-link to="/sports" tag="span" style="cursor: pointer">-->
<!--                            <img src="../../assets/images/main/ptn/bn01.png" alt="">-->
<!--                        </router-link>-->
<!--                    </div>-->

<!--                    <div class="sub">-->
<!--                        <router-link to="/sports_inplay" tag="span">-->
<!--                            <img src="../../assets/images/main/ptn/bn02.png" alt="">-->
<!--                        </router-link>-->
<!--                    </div>-->
<!--                    <div class="sub">-->
<!--                        <router-link to="/leisure/eospowerball1m" tag="span">-->
<!--                            <img src="../../assets/images/main/ptn/bn03.png" alt="">-->
<!--                        </router-link>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;                    <div class="sub">&ndash;&gt;-->
<!--                    &lt;!&ndash;                        <router-link to="/leisure/bet365_superleague" tag="span">&ndash;&gt;-->
<!--                    &lt;!&ndash;                            <img src="../../assets/images/main/ptn/bn08.jpg" alt="">&ndash;&gt;-->
<!--                    &lt;!&ndash;                        </router-link>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                    <div class="sub">-->
<!--                        <router-link to="/casinohonor" tag="span">-->
<!--                            <img src="../../assets/images/main/ptn/bn04.png" alt="">-->
<!--                        </router-link>-->
<!--                    </div>-->
<!--                    <div class="sub pc">-->
<!--                        <router-link to="/event" tag="span">-->
<!--                            <img src="../../assets/images/main/ptn/bn05.png" alt="">-->
<!--                        </router-link>-->
<!--                    </div>-->

<!--                </div>-->


                <div class="sub_mobile">
                    <!--                    <mobile-menu-items-comp></mobile-menu-items-comp>-->
                    <right-bar-banner-comp></right-bar-banner-comp>

                </div>
            </div>
        </div>
        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart" :display-position="displayPosition"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import TopbarComp from "../../components/TopbarComp";
    import {swiper, swiperSlide} from "vue-awesome-swiper"
    import "swiper/dist/css/swiper.css";
    import FootComp from "../../components/FootComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import inplayConst from "../../common/inplayConst";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {getSportsInplayGames} from "../../network/sportsInplayRequest";
    import {WEBSOCKET_URL} from "../../common/const";
    import {changePoint, getEvent, getNoticeList} from "../../network/userRequest";
    import {postionMixin} from "../../common/mixin";
    import MobileMenuItemsComp from "../../components/MobileMenuItemsComp";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsMainShowComp from "../../components/sports/SportsMainShowComp";
    import {getRecentlyEndGames, getRecentlyGames} from "../../network/sportsRequest";
    import PopupComp from "../../components/PopupComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";

    export default {
        name: "Main",
        mixins: [postionMixin],
        components: {
            PopupComp,
            SportsMainShowComp,
            RightButtonsComp,
            UserInfoComp,
            MobileMenuItemsComp,
            SportsBetCartComp,
            RightBarBannerComp,
            LeftBarComp, SportsLeftBarComp, FootComp, TopbarComp, RightBarComp, swiper, swiperSlide
        },
        data() {
            return {
                noticeList: [],
                eventList: [],
                endgameList: [],
                noticePageSize: 8,
                inplayList: [],
                wsData: null,
                websocket: null,
                path: WEBSOCKET_URL,
                position: "메인",
                sportsConst,
                inplayConst,
                displayPosition: this.$store.state.displayPostion,
                soccer: [],
                base: [],
                basket: [],
                hokey: [],
                esports: [],
                vol: [],
                swiperOption: {
                    pagination: {el: '.swiper-pagination'},
                    autoplay: {},
                    disableOnInteraction: false,
                    delay: 2000,
                },
            }
        },
        methods: {
            changeImg(img){
                console.log(img)
            },
            initUserInfo() {
                this.$store.dispatch('actionUserInfo')
            },
            changePoint() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                changePoint().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (!res.data.success) {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.initUserInfo()
                    }
                })
            },
        },
        created() {
            getRecentlyGames().then(res => {
                if (res.data.success) {
                    let recentlryGames = res.data.data
                    this.soccer = recentlryGames.soccer
                    this.basket = recentlryGames.bascket
                    this.base = recentlryGames.base
                    this.vol = recentlryGames.vol
                    this.hokey = recentlryGames.hockey
                    this.esports = recentlryGames.esports
                }
            })
            getRecentlyEndGames().then(res => {
                this.endgameList = res.data.data
            })
            getNoticeList(3).then(res => {
                this.noticeList = res.data.data
            })
            getEvent(1, 3).then(res => {
                this.eventList = res.data.data
            })
        }
    }
</script>
<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");

    .main .sub_img {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
    }

    .main .sub_img .sub {
        width: 19.5%;
        cursor: pointer;
    }

    .main .sub_img img {
        width: 100%;
        --animation: bannerLamp 15s ease infinite;
    }
    .main .sub_img img:hover{
        opacity: 0.5;
        transition: 0.7s;
    }


    @keyframes bannerLamp {
        0% {
            transform: rotate(0deg);
        }
        25% {
            transform: rotate(5deg);
        }
       50% {
            transform: rotate(-5deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }


    .main .sub_mobile {
        display: none;
        width: 100%;
    }

    .main .mobile {
        display: none;
    }

    .main .main_btns1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 0 0;
        padding: 0 5px;
    }

    .main .main_btns1 img {
        width: 20px;
    }

    .main_btns1 .menu1 {
        width: 24.6%;
        background-color: #1f1f24;
        border: 1px solid #313138;
        line-height: 42px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        border-radius: 3px;
    }

    .main .main_btns2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
        flex-wrap: wrap;
    }

    .main_btns2 .menu2 {
        width: 33%;
        background-color: #1f1f24;
        border: 1px solid #313138;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        margin-top: 5px;
        border-radius: 3px;
        padding: 20px 0px;
    }

    .main_btns2 .menu2 img {
        width: 40px;
        margin: 3px 0;
    }


    .noticeandresult {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 10px;
        color: #bcbcbc;
    }

    .noticeandresult .nt {
        width: 49.6%;
        text-align: left;
        padding: 0 10px;
        background-color: #161616;
    }

    .noticeandresult .nt .nt_title {
        width: 100%;
        height: 42px;
        line-height: 42px;
        border-bottom: 2px solid #b04e11;
        padding-left: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #fff9ff;
        margin-bottom: 10px
    }

    .noticeandresult .nt .nt_cont {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-left: 10px;
        font-size: 14px;;
        font-weight: bold;
        cursor: pointer
    }
    .noticeandresult .nt .nt_cont img{
        height: 20px;
    }

    .noticeandresult .res {
        width: 60%;
        text-align: right;
        padding: 0 10px;
    }

    .noticeandresult .res div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }


    @media screen and (max-width: 1024px) {
        .main .sub_img .sub {
            width: 49.5% !important;
            padding: 2px 0px;
            margin-bottom: 5px;
        }

        .main .sub_mobile {
            display: block !important;
        }

        .main .pc {
            display: none !important;
        }

        .main .mobile {
            display: block !important;
        }

        .noticeandresult {
            width: 100%;
            flex-wrap: wrap;
        }

        .noticeandresult .nt {
            width: 100% !important;
        }

        .noticeandresult .res {
            width: 100% !important;
            margin-top: 20px;
        }
    }


</style>
