<template>
  <div class="popup">

    <div class="popup_section" v-for="(item,index) in plist"
         :key="index" style=""
         :style="{zIndex: 10000-index}"
         v-show="item.isShow && $store.state.popups.indexOf(item.id) === -1">

      <div class="pop_box">
        <div class="event_img">
          <img v-if="item.type === 2" :src="item.imageUrl" alt="" style="width: 100%;">
          <span v-else v-html="item.content" style="padding: 5px"> </span>
        </div>
      </div>
      <div class="pfoot" style="">
        <div @click="close24(item)" style="text-align: left;cursor: pointer">
          <i class="fa fa-square"></i> Close Today
        </div>
        <div style="text-align: right;cursor: pointer">
          <button class="btn-close" @click="close(item)">Close</button>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import {getPopupList} from "../network/userRequest";
import sportsConst from "../common/sportsConst";

export default {
  name: "PopupComp",
  data() {
    return {
      plist: []
    }
  },
  methods: {
    close24(item) {
      this.$set(item, 'isShow', false)
      this.$cookies.set('pop' + item.id, '1', 60 * 60 * 12, '/')
    },
    close(item) {
      //this.$store.state.popups.push(item.id)
      this.$set(item, 'isShow', false)
    },
    getPopList() {
      getPopupList(sportsConst.POPUP_PLATFORM_MAIN).then(res => {
        if (res.data.success) {
          this.plist = res.data.data
          this.plist.forEach(popup => {
            let cookiePopupStatus = this.$cookies.get('pop' + popup.id)
            this.$set(popup, 'isShow', cookiePopupStatus == null)

          })
        }
      })
    }
  },
  created() {
    if (this.$route.path.indexOf('/main') != -1) {
      this.getPopList()
    }

  }
}
</script>

<style scoped>

.popup {
  position: absolute;
  z-index: 100000000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 1170px;
  left: 50%;
  top: 27%;
  -webkit-transform: translateX(-50%) translateY(-30%) scale(1);
  transform: translateX(-50%) translateY(-30%) scale(1);

}

.popup_section {
  background-color: #1e1e1e;
  color: #fff9ff;
  margin: 5px;
  width: 375px;
  --border-image: linear-gradient(to right, #ffd680, #ffd680) 1;
  --box-shadow: 0 0 15px #fee394;
}

.popup_section h4 {
  line-height: 20px;
  height: 20px;
  width: 100%;
  font-size: 12px;
  padding: 20px;
  color: #cca4fd;
}

.popup_section .btn-close {
  padding: 8px 8px;
  background-color: #f1ae17;
  color: #0c0e0e;
}

.pop_box {
  box-sizing: border-box;
}

.pop_box h2 span:hover {
  color: #f19f09;
}

.popup_section .pfoot {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  line-height: 40px
}

.pfoot div {
  width: 45%;
  font-size: 15px;
  font-weight: bold;
  padding: 0 5px;
}

@media screen and (max-width: 2560px) {
  .popup {
    -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
    transform: translateX(-50%) translateY(-50%) scale(1);

  }
}

@media screen and (max-width: 1920px) {
  .popup {
    -webkit-transform: translateX(-50%) translateY(-37%) scale(1);
    transform: translateX(-50%) translateY(-37%) scale(1);

  }
}

@media screen and (max-width: 1024px) {
  .popup {
    width: 100% !important;
    top: 0;
  }

  .popup_section {
    position: absolute !important;
    width: 100% !important;
    padding: 5px;

  }

  .popup_section .pfoot {
    line-height: 60px;
  }
}
</style>